import './Format.css';
import React from 'react';

function Format({ matchData }) {
    const showScore = matchData?.VolatileData?.ShowScore ?? false;

    if (!showScore || matchData?.Data?.Event?.Name === ' ') {
        return (
            <div></div>
        );
    }

    return (
        <div className={'Format' + ((showScore && matchData?.Data?.Format?.Name) ? ' FormatVisible' : ' FormatHidden')}>
            <div className='FormatHeader'>
                {matchData?.Data?.Format?.Name ?? ''}
            </div>
            <div className='FormatBody'>
                {matchData?.Data?.Format?.Value ?? ''}
            </div>
        </div>
    );
}

export default Format;
