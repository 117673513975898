import './variables.css'
import './MFOverlay.css';
import React, { useState, useEffect } from 'react';
import Scoreboard from './components/Scoreboard/Scoreboard';
import ScoreboardWft from './components/ScoreboardWft/ScoreboardWft';
import Logo from './components/Logo/Logo';
import Odds from './components/Odds/Odds';
import Stats from './components/Stats/Stats';
import TableNumber from './components/TableNumber/TableNumber';

function MFOverlay() {
    const [matchData, setMatchData] = useState({});

    const queryParameters = new URLSearchParams(window.location.search);
    const organization = queryParameters.get('organization');
    const tournament = queryParameters.get('tournament');
    const station = queryParameters.get('station');
    const showOdds = matchData?.VolatileData?.ShowOdds ?? false;
    const theme = matchData?.VolatileData?.Theme ?? 'MF';

    useEffect(() => {
        const interval = setInterval(() => {
            if (organization && tournament && station) {
                fetch(`https://modern-foosball-api.azurewebsites.net/api/organizations/${organization}/tournaments/${tournament}/stations/${station}/CurrentMatch`)
                    .then(response => response ? response.json() : {})
                    .then(data => setMatchData((previous) => { if (previous) { delete previous.previous; }; data.Previous = previous; return data; }))
                    .catch(function onError(error) {
                        setMatchData({});
                    });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [organization, station, tournament]);

    return (
        <div className={'MFOverlay ' + theme}>
            <Logo matchData={matchData} />
            <TableNumber matchData={matchData} />
            <Stats matchData={matchData} />
            {theme === 'MF' && <Scoreboard matchData={matchData} />}
            {theme === 'WFT' && <ScoreboardWft matchData={matchData} />}
            {showOdds && <Odds matchData={matchData} />}
        </div>
    );
}

export default MFOverlay;
