import './variables.css'
import './MFStats.css';
import React, { useState, useEffect } from 'react';

function MFStats() {
    const [matchData, setMatchData] = useState({});

    const queryParameters = new URLSearchParams(window.location.search);
    const organization = queryParameters.get('organization');
    const tournament = queryParameters.get('tournament');
    const station = queryParameters.get('station');

    useEffect(() => {
        const interval = setInterval(() => {
            if (organization && tournament && station) {
                fetch(`https://modern-foosball-api.azurewebsites.net/api/organizations/${organization}/tournaments/${tournament}/stations/${station}/CurrentMatch`)
                    .then(response => response ? response.json() : {})
                    .then(data => setMatchData((previous) => { if (previous) { delete previous.previous; }; data.Previous = previous; return data; }))
                    .catch(function onError(error) {
                        setMatchData({});
                    });
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [organization, station, tournament]);

    return (
        <MFStatsTable matchData={matchData} />
    );
}

function MFStatsTable({ matchData }) {
    if (matchData?.Data?.Id === ' _ _ _ ') {
        return (
            <div className='MFStatsEmpty'>No active match.</div>
        );
    }

    const statsAvailable = matchData?.Data?.Yellow?.Stats != null && matchData?.Data?.Black?.Stats != null;

    if (!statsAvailable) {
        return (
            <div className='MFStatsEmpty'>Checking...</div>
        );
    }

    const data = GetStatsRows(matchData);
    const rows = data.map((item, index) =>
        item.RowType === 'Header'
            ? <tr key={index}>
                <th colSpan={3} className={'Header'}>{item.Title}</th>
            </tr>
            : <tr key={index}>
                <td className={'Yellow' + ((item.Style || '').includes('FirstRow') ? ' TopLeft' : '') + ((item.Style || '').includes('LastRow') ? ' BottomLeft' : '')}>{item.Yellow}</td>
                <td className='Title'>{item.Title}</td>
                <td className={'Black' + ((item.Style || '').includes('FirstRow') ? ' TopRight' : '') + ((item.Style || '').includes('LastRow') ? ' BottomRight' : '')}>{item.Black}</td>
            </tr>
    );

    return (
        <table className='MFStatsTable'>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
}

function GetStatsRows(matchData) {
    var rows = [];

    // Match info
    rows.push({ RowType: 'Header', Title: matchData.Data.Event.Name + (matchData.Data.Bracket ? ' ' + matchData.Data.Bracket : '') });
    if (matchData.Data.Yellow.Players.length === 2) {
        rows.push({ RowType: 'Data', Title: 'Forward', Yellow: matchData.Data.Yellow.Players[0].FullName, Black: matchData.Data.Black.Players[0].FullName, Style: 'FirstRow' });
        rows.push({ RowType: 'Data', Title: 'Goalie', Yellow: matchData.Data.Yellow.Players[1].FullName, Black: matchData.Data.Black.Players[1].FullName });
    } else if (matchData.Data.Yellow.Players.length === 1) {
        rows.push({ RowType: 'Data', Title: 'VS', Yellow: matchData.Data.Yellow.Players[0].FullName, Black: matchData.Data.Black.Players[0].FullName, Style: 'FirstRow' });
    }

    rows.push({ RowType: 'Data', Title: 'Games', Yellow: matchData.Data.Yellow.Games, Black: matchData.Data.Black.Games });
    rows.push({ RowType: 'Data', Title: 'Points', Yellow: matchData.Data.Yellow.Points, Black: matchData.Data.Black.Points, Style: 'LastRow' });

    // 5-Bar
    rows.push({ RowType: 'Header', Title: '5-Bar' });
    rows.push({ RowType: 'Data', Title: 'Passing', Yellow: `${matchData.Data.Yellow.Stats.X5Y}-for-${matchData.Data.Yellow.Stats.X5T}    (${matchData.Data.Yellow.Stats.X5PERCENTAGE}%)`, Black: `${matchData.Data.Black.Stats.X5Y}-for-${matchData.Data.Black.Stats.X5T}    (${matchData.Data.Black.Stats.X5PERCENTAGE}%)`, Style: 'FirstRow LastRow' });

    // 3-Bar
    rows.push({ RowType: 'Header', Title: '3-Bar' });
    rows.push({ RowType: 'Data', Title: 'Shooting', Yellow: `${matchData.Data.Yellow.Stats.X3Y}-for-${matchData.Data.Yellow.Stats.X3T}    (${matchData.Data.Yellow.Stats.X3PERCENTAGE}%)`, Black: `${matchData.Data.Black.Stats.X3Y}-for-${matchData.Data.Black.Stats.X3T}    (${matchData.Data.Black.Stats.X3PERCENTAGE}%)`, Style: 'FirstRow LastRow' });

    // 2-Bar
    rows.push({ RowType: 'Header', Title: '2-Bar' });
    rows.push({ RowType: 'Data', Title: 'Clearing', Yellow: `${matchData.Data.Yellow.Stats.X2C}-for-${matchData.Data.Yellow.Stats.X2CT}    (${matchData.Data.Yellow.Stats.X2CPERCENTAGE}%)`, Black: `${matchData.Data.Black.Stats.X2C}-for-${matchData.Data.Black.Stats.X2CT}    (${matchData.Data.Black.Stats.X2CPERCENTAGE}%)`, Style: 'FirstRow' });
    rows.push({ RowType: 'Data', Title: 'On Goal', Yellow: `${matchData.Data.Yellow.Stats.XSOG}`, Black: `${matchData.Data.Black.Stats.XSOG}` });
    rows.push({ RowType: 'Data', Title: 'Passing', Yellow: `${matchData.Data.Yellow.Stats.X2P}-for-${matchData.Data.Yellow.Stats.X2PT}    (${matchData.Data.Yellow.Stats.X2PPERCENTAGE}%)`, Black: `${matchData.Data.Black.Stats.X2P}-for-${matchData.Data.Black.Stats.X2PT}    (${matchData.Data.Black.Stats.X2PPERCENTAGE}%)`, Style: 'LastRow' });

    // Scoring
    rows.push({ RowType: 'Header', Title: 'Scoring' });
    rows.push({ RowType: 'Data', Title: 'Total', Yellow: `${matchData.Data.Yellow.Stats.XSCORE}`, Black: `${matchData.Data.Black.Stats.XSCORE}`, Style: 'FirstRow' });
    rows.push({ RowType: 'Data', Title: '3-Bar', Yellow: `${matchData.Data.Yellow.Stats.X3Y}`, Black: `${matchData.Data.Black.Stats.X3Y}` });
    rows.push({ RowType: 'Data', Title: '5-Bar', Yellow: `${matchData.Data.Yellow.Stats.X5G}`, Black: `${matchData.Data.Black.Stats.X5G}` });
    rows.push({ RowType: 'Data', Title: '2-Bar', Yellow: `${matchData.Data.Yellow.Stats.X2G}`, Black: `${matchData.Data.Black.Stats.X2G}` });
    rows.push({ RowType: 'Data', Title: 'Breaks', Yellow: `${matchData.Data.Yellow.Stats.XSL}`, Black: `${matchData.Data.Black.Stats.XSL}` });
    rows.push({ RowType: 'Data', Title: 'Stuffs', Yellow: `${matchData.Data.Yellow.Stats.XST}`, Black: `${matchData.Data.Black.Stats.XST}`, Style: 'LastRow' });

    return rows;
}

export default MFStats;
