import './Bracket.css';
import React from 'react';

function Bracket({ matchData }) {
    let bracketName = matchData?.VolatileData?.ShowCustomBracket === true
        ? (matchData?.VolatileData?.CustomMatchInfo?.Bracket ?? '')
        : (matchData?.Data?.Bracket ?? '');
    
    if (matchData?.Data?.Event?.Name === ' ') {
        bracketName = '';
    }

    let padding = '0px';
    if (bracketName.length <= 5) {
        padding = '30px';
    } else if (bracketName.length === 6) {
        padding = '35px';
    }

    return (
        <div className='Bracket'>
            <div className='BracketPadding' style={{ width: padding }}></div>
            {bracketName}
        </div>
    );
}

export default Bracket;
