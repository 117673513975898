import './Odds.css';
import '../../styles/TeamColors.css'
import { useSpring, animated } from 'react-spring';

function Odds({ matchData }) {
    const theme = matchData?.VolatileData?.Theme;
    const queryParameters = new URLSearchParams(window.location.search);
    const swapColors = queryParameters.get('swapColors')?.toLowerCase() === 'true';

    const previousLeftTeamOdds = (swapColors ? matchData?.Previous?.Data?.Odds?.Black : matchData?.Previous?.Data?.Odds?.Yellow) ?? 50;
    const currentLeftTeamOdds = (swapColors ? matchData?.Data?.Odds?.Black : matchData?.Data?.Odds?.Yellow) ?? 50;
    const previousRightTeamOdds = 100 - previousLeftTeamOdds;
    const currentRightTeamOdds = 100 - currentLeftTeamOdds;

    const previousPosition = calculateHeaderPosition(previousLeftTeamOdds);
    const position = calculateHeaderPosition(currentLeftTeamOdds);

    const moveSlider = useSpring({
        from: { left: `${previousPosition}%` },
        to: { left: `${position}%` }
    });

    const moveLeftTeam = useSpring({
        from: { width: `${clamp(previousLeftTeamOdds, 5, 95)}%` },
        to: { width: `${clamp(currentLeftTeamOdds, 5, 95)}%` }
    });

    const moveRightTeam = useSpring({
        from: { width: `${clamp(previousRightTeamOdds, 5, 95)}%` },
        to: { width: `${clamp(currentRightTeamOdds, 5, 95)}%` }
    });

    if (!matchData?.Data?.Odds || matchData?.Data?.Event?.Name === ' ' || theme !== 'MF') {
        return (
            <div></div>
        );
    }

    return (
        <div className='Odds'>
            <div className='OddsLine'>
                <animated.div className={(swapColors ? 'BlackTeamColors' : 'YellowTeamColors') + ' ' + theme} style={moveLeftTeam}></animated.div>
                <animated.div className={(swapColors ? 'YellowTeamColors' : 'BlackTeamColors') + ' ' + theme} style={moveRightTeam}></animated.div>
            </div>

            <animated.div className='OddsHeader' style={moveSlider}>
                <div className={'OddsHeaderLeftTeam ' + (swapColors ? 'BlackTeamColors' : 'YellowTeamColors') + ' ' + theme}>{currentLeftTeamOdds}</div>
                <div className='OddsHeaderCenter'>Win %</div>
                <div className={'OddsHeaderRightTeam ' + (swapColors ? 'YellowTeamColors' : 'BlackTeamColors') + ' ' + theme}>{currentRightTeamOdds}</div>
            </animated.div>
        </div>
    );
}

function calculateHeaderPosition(leftTeamOdds) {
    // The odds slider takes up 10% of horizontal space.
    return leftTeamOdds * 0.9;
}

function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

export default Odds;
